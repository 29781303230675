.button {
  height: 68px;
  border: none;
  outline: none;
  text-decoration: none;
  user-select: none;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
	cursor: pointer;
	position: relative;
  text-transform: uppercase;
  border-radius: 8px;
  background: #0054A6;
	transition: .3s ease-out;

	&:hover {
		background: #00498F;
	}

	&:active {
		.icon-play,
		.button__text {
			opacity: .3;
		}
	}

	&_full {
		width: 100%;
	}

  &__text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
		transition: .3s ease-out;
  }
}

a.button {
	padding-left: 45px;
	padding-right: 45px;
	display: inline-block;
}