.is-header-menu-opened {
  .header-menu {
    display: block;
  }

  .header-burger {
    img {
      &:first-child {
        opacity: 0;
      }

      &:last-child {
        opacity: 1;
      }
    }
  }
}

.header {
  height: 80px;
  position: absolute;
  top: 74px;
  left: 80px;
  right: 80px;
  z-index: 10;
  color: #000;
  border-radius: 18px;
  border: 1px solid #eaeaea;
  background: #fff;

  &_is_sticky {
    position: fixed;
    top: 34px;
  }

  @include respond-max('desktop') {
    top: 50px;
    left: 50px;
    right: 50px;

    &_is_sticky {
      top: 20px;
    }
  }

  @include respond-max('tablet') {
    top: 26px;
    left: 26px;
    right: 26px;
    height: 56px;
    border-radius: 15px;

    &_is_sticky {
      top: 16px;
    }
  }
}

.header-container {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  @include respond-max('desktop') {
    justify-content: space-between;
    padding: 0 20px 0 32px;
  }

  @include respond-max('tablet') {
    padding: 0 12px;
  }
}

.header-logo {
  transition: 0.3s ease-out;

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 0.3;
  }

  @include respond-min('desktop') {
    position: absolute;
    top: 14px;
    right: 20px;
  }

  @include respond-max('tablet') {
    img {
      width: 90px;
    }
  }
}

.header-nav {
  display: flex;
  gap: 20px;
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
  white-space: nowrap;

  &__link {
    transition: 0.3s ease-out;

    &:hover {
      opacity: 0.6;
    }

    &:active {
      opacity: 0.3;
    }
  }

  @include respond-max('tablet') {
    display: none;
  }
}

.header-burger {
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: relative;

  @include respond-min('tablet') {
    display: none;
  }

  img {
    @include abscenter();

    &:last-child {
      opacity: 0;
    }
  }
}

.header-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f1f1f1;
  padding: 120px 45px;
  display: none;
  z-index: 9;

  &__nav {
    display: flex;
    flex-direction: column;
    gap: 26px;
  }

  &__link {
    font-size: 36px;
    font-weight: 600;
    line-height: 1;
  }
}
