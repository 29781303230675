.footer {
  padding-bottom: 40px;
  color: #444;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;

  @include respond-max('desktop') {
    margin-top: 55px;
  }

  @include respond-max('tablet') {
    margin-top: 30px;
  }
}

.footer-copy {
  opacity: 0.3;
  transition: .3s ease-out;

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 0.1;
  }
}
