.fast-box {
  padding: 120px 80px;
  border-radius: 30px;
  background: #f0f0f0;
  margin-bottom: 30px;

  @include respond-max('desktop') {
    padding: 80px 30px;
  }

  @include respond-max('tablet') {
    padding: 40px 20px;
    margin-bottom: 16px;
  }
}

.fast-list {
  font-size: 28px;
  font-weight: 400;
  letter-spacing: -0.84px;
  border-top: 1px solid #b6b6b6;

  @include respond-max('tablet') {
    font-size: 20px;
  }

  &__link {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #b6b6b6;
    transition: 0.3s ease-out;

    @include respond-max('desktop') {
      padding: 20px;
    }

    @include respond-max('tablet') {
      padding: 15px 10px;
    }

    &:hover {
      background-color: #e9e9e9;

      .fast-list__icon {
        color: #0054a6;
      }
    }

    &:active {
      color: rgba(0, 0, 0, 0.3);
      
      .fast-list__icon {
        opacity: 0.3;
      }
    }
  }

  &__icon {
    color: #b6b6b6;
    transition: 0.3s ease-out;

    svg {
      width: 40px;
      height: 36px;
    }

    @include respond-max('tablet') {
      display: none;
    }
  }
}
