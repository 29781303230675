.banner {
  display: flex;
  border-radius: 30px;
  background: #0054a6;
  overflow: hidden;

  @include respond-max('desktop') {
    display: block;
  }

  @include respond-max('tablet') {
    border-radius: 20px;
  }
}

.banner-text {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 64px;
  font-weight: 700;

  @include respond-max('desktop') {
    width: 100%;
    height: 375px;
  }

  @include respond-max('tablet') {
    height: 342px;
    font-size: 48px;
    padding: 0 40px;
    text-align: center;
  }
}

.banner-image {
  width: 40%;

  @include respond-max('desktop') {
    width: 100%;
  }

  img {
    width: 100%;
    display: block;
    border-radius: 0 30px 30px 0;

    @include respond-max('desktop') {
      border-radius: 30px 30px 0 0;
    }
  }
}