.contact-cards {
  padding: 0 80px;
  display: flex;
  gap: 30px;
  margin-bottom: 315px;

  @include respond-max('desktop') {
    flex-wrap: wrap;
    padding: 0 60px;
    max-width: 708px;
    margin: 0 auto 150px;
  }

  @include respond-max('tablet') {
    padding: 0;
    gap: 16px;
    margin-bottom: 16px;
    margin-bottom: 16px;
  }
}

.contact-card {
  width: 25%;
  height: 323px;
  display: flex;
  flex-direction: column;
  padding: 40px 16px 16px;
  border-radius: 20px;
  border: 1px solid #d3d3d3;
  transition: .3s ease-out;
  
  &:hover {
    border-color: #0054A6;
  }

  @include respond-max('desktop') {
    width: calc(50% - 15px);
  }

  @include respond-max('tablet') {
    width: 100%;
  }

  &__icon {
    margin: 0 auto 20px;
  }

  &__text {
    max-width: 218px;
    min-height: 62px;;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.72px;
    text-align: center;
    margin: 0 auto;
  }

  &__button {
    margin-top: auto;

    .button {
      width: 100%;
    }
  }
}
