.review {
  border-radius: 30px;
  border: 1px solid #ccc;
  background: #f0f0f0;
  padding: 120px 80px 150px;
  margin-bottom: 30px;

  @include respond-max('desktop') {
    padding: 60px;
  }

  @include respond-max('tablet') {
    border-radius: 20px;
    padding: 40px 20px 20px;
    margin-bottom: 16px;
  }
}

.review-cards {
  display: flex;
  gap: 30px;

  @include respond-max('desktop') {
    display: block;
  }
}

.review-card {
  width: 33.3333%;
  height: 534px;
  padding: 30px 20px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid #ccc;
  background: #fff;
  transition: .3s ease-out;

  &:hover {
    box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.30);
  }

  @include respond-max('desktop') {
    width: 100%;
    height: 492px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  @include respond-max('tablet') {
    height: auto;
    min-height: 492px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  
  &__icon {
    margin-bottom: 30px;

    img {
      max-height: 68px;
    }
  }

  &__title {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.84px;
    text-transform: uppercase;
    margin-bottom: 13px;
  }

  &__info {
    margin-top: auto;

    @include respond-max('tablet') {
      margin-top: 30px;
    }
  }

  &__desc {
    padding: 0 10px;
  }

  &__author,
  p {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.72px;
  }

  &__author {
    margin-bottom: 13px;
    padding-right: 10px;
  }
}
