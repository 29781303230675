.hero {
  position: relative;
  height: calc(100vh - 80px);
  border-radius: 30px;
  margin-bottom: 160px;
  @include backcover();

  @include respond-max('desktop') {
    height: 994px;
    margin-bottom: 110px;
  }

  @include respond-max('tablet') {
    height: 624px;
    border-radius: 20px;
    margin-bottom: 40px;
  }
}

.hero-text {
  position: absolute;
  bottom: 31%;
  right: calc(50% - 250px);
  color: #fff;
  text-align: right;
  text-shadow: 0px 16px 16px rgba(0, 0, 0, 0.35);
  font-size: 140px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: -4.2px;

  @include respond-max('desktop') {
    right: 40px;
    bottom: auto;
    top: 132px;
    font-size: 132px;
    letter-spacing: -3.96px;
  }

  @include respond-max('tablet') {
    font-size: 72px;
    top: 87px;
    right: 18px;
  }
}

.hero-image {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40%;

  img {
    display: block;
    width: 100%;
  }

  @include respond-max('desktop') {
    width: 100%;
    max-width: 708px;
    margin: auto;
    left: 0;
  }
}
