/**
 * Variables
 */

 @import 'settings/colors';
 @import 'settings/breakpoints';

 /**
  * Functions, mixins
  */

 @import 'tools/functions';
 @import 'tools/mixins';

/**
 * Basic rules
 */

@import 'base/base';
@import 'base/fonts';

/**
 * Vendor
 */

@import 'vendor/import';

/**
 * Components
 */

@import 'components/icons';
@import 'components/button';
@import 'components/container';
@import 'components/header';
@import 'components/footer';
@import 'components/section';
@import 'components/hero';
@import 'components/contact';
@import 'components/fast-box';
@import 'components/blue-box';
@import 'components/review';
@import 'components/banner';