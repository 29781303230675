.container {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 80px;

  @include respond-max('tablet') {
    padding: 0 20px;
  }
}
