/*
@font-face {
  font-family: 'FONTNAME';
  src: url('../fonts/FONTNAME.eot');
  src: url('../fonts/FONTNAME.eot?#iefix')format('embedded-opentype'),
    url('../fonts/FONTNAME.woff2') format('woff2'),
    url('../fonts/FONTNAME.woff') format('woff'),
    url('../fonts/FONTNAME.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
*/
