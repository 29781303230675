.blue-box {
  padding: 120px 80px 150px;
  color: #fff;
  border-radius: 30px;
  background-color: #0054a6;
  margin-bottom: 30px;

  @include respond-max('desktop') {
    padding: 60px;
  }
  
  @include respond-max('tablet') {
    padding: 40px 20px 20px;
    margin-bottom: 16px;
  }

  .section-title {
    color: #fff;
  }

  &__layer {
    color: #000;
    display: flex;
    padding: 30px;
    border-radius: 20px;
    border: 1px solid #ccc;
    background: #fff;

    @include respond-max('desktop') {
      display: block;
    }
    
    @include respond-max('tablet') {
      padding: 20px;
    }
  }

  &__title {
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -1.08px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__icon {
    margin-bottom: 50px;

    @include respond-max('tablet') {
      margin-bottom: 26px;
    }
  }

  &__image {
    width: 50%;

    @include respond-max('desktop') {
      width: 100%;
      margin-bottom: 55px;
    }

    @include respond-max('tablet') {
      margin-bottom: 30px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__section {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-right: 60px;

    @include respond-max('desktop') {
      width: 100%;
      padding-right: 0;
    }
  }

  p {
    font-size: 28px;
    font-weight: 400;
    letter-spacing: -0.84px;
  }

  .button {
    margin-top: auto;

    @include respond-max('desktop') {
      margin-top: 90px;
    }

    @include respond-max('tablet') {
      margin-top: 30px;
    }
  }
}
