.icon-whatsapp {
  width: 18px;
  height: 18px;
}

.icon-telegram {
  width: 21px;
  height: 18px;
}

.icon-email {
  width: 18px;
  height: 18px;
}

.icon-play {
  width: 8px;
  height: 12px;
}

.icon-arrow-left,
.icon-arrow-right {
  width: 8px;
  height: 16px;
}