.section-title {
  color: #0054a6;
  font-size: 64px;
  font-weight: 700;
  margin-bottom: 80px;

  @include respond-max('tablet') {
    font-size: 48px;
    margin-bottom: 46px;
  }
}
